<template>
  <div class="container" v-loading="loading">

    <div class="row">
      <div class="col-md-9">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <div class="course-details-title">
              {{item.title}}
          </div>
    
          <div class="course-details-time">
            视频时间：{{item.created}} &nbsp;&nbsp;|&nbsp;&nbsp;
            浏览：{{item.view}}
          </div>
    
          <div class="row course-details">
            <iframe :src="item.iframe_src" class="iframe-src" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
            <div class="video-details-content" v-html="item.content"></div>
          </div>
        </div>
      </div>
  
      <div class="col-md-3 sidebar-right">
        
        <!-- <ul class="list-group video-sidebar-categories">
          <li class="list-group-item active" aria-current="true">分类</li>
          <div v-for="cat in videoCategories" :key="cat.id">
            <a :href="'/video/category/details?id='+cat.id">
              <li class="list-group-item">{{ cat.title }}</li>
            </a>
          </div>
        </ul> -->

        <ul class="list-group video-sources">
          <li class="list-group-item active" aria-current="true">博主</li>
          <div v-for="cat in videoSources" :key="cat.id">
            <a :href="'/video/source/details?mid='+cat.mid">
              <li class="list-group-item">{{ cat.title }}</li>
            </a>
          </div>
        </ul>

      </div>
    </div>
    
    
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CourseDetails',
  props:['user'],
  data() {
    return {
      item: [],
      videoCategories: [],
      videoSources: [],
      loading: true,
    }
  },
  created() {
    this.getDetails()
    this.videoCategoryList()
    this.videoSourceList()
  },
  methods: {
    async getDetails() {
      const response = await axios.post('/api/web/video/details?id='+this.$route.query.id)
      this.item = response.data.data
      console.log(this.item)
      this.loading = false
    },
    async videoCategoryList() {
      const response = await axios.post('/api/web/video/category/list')
      this.videoCategories = response.data.data.data
      console.log(this.videoCategories)
      this.loading = false
    },
    async videoSourceList() {     
      const response = await axios.post('/api/web/video/source/list')
      const items = response.data.data.data
      this.videoSources = items
      this.loading = false
    }
  }
}
</script>

<style>
/* 电脑/平板设备屏幕，默认屏幕（大于等于 768px） */
@media (min-width: 768px) {
  .iframe-src {
      width: 100%;
      height: 480px;
  }
}

/* 手机屏幕（小于等于 767px） */
@media (max-width: 768px) {
  .iframe-src {
      width: 100%;
      height: 250px;
  }
}
</style>
